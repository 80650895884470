@import "../../../scss/imports";

.FadeInLoader {
  @keyframes loaderFadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  animation-name: loaderFadeIn;
  animation-duration: 4s;
}
